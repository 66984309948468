import React from 'react';
import { EmptyView } from '../components/Loading/Empty';
import { Wheel_ as Wheel } from '../components/wheel';
import { validateResponse } from '../components/wheel/validators';
import { useStore } from '../context/store';
import { RouletteController } from '../service/dbController';
import '../styles.css';
import { loadRouletteParams, setRouletteStyles } from '../utils/roulette'


export const RouletteWrapper = () => {
  const campaigns = useStore().data.campaign;
  const settings = useStore().data.settings
  const activeCampaignID = (settings?.general?.campaign_active)
  if (!activeCampaignID) { return <EmptyView></EmptyView> }
  const activeCampaign = campaigns[activeCampaignID]
  const probs = ((activeCampaign?.setting_data) || [])
    .map((item, i) => ({ ...item, index: i }))

  const [rouletteParams, setRouletteParams] = React.useState({})
  const [selectedId, setSelectedId] = React.useState(undefined)

  const spin_roulette = (rC) => {
    rC.spin_roulette(({ error, data }) => {
      if (!error) { setSelectedId(data?.data?.id) }
    })
  }

  const onModalSubmit = (response) => {
    const rC = new RouletteController()
    const userMiniParser = (user = {}) => {
      const parsed = {}
      parsed.ci_number = (user?.ci_Number) || "",
        parsed.phone_number = (user?.phone_Number) || ""
      return parsed
    }

    rC.register(userMiniParser(response), ({ error, data }) => {
      validateResponse(data?.error)
      spin_roulette(rC)
    })

  }

  React.useEffect(() => {
    // We're using this cos the styles of the roulette depends of ids
    const rouletteParams = loadRouletteParams(activeCampaign)
    setRouletteParams({ ...rouletteParams })
    setRouletteStyles({ ...rouletteParams })

  }, [useStore().data])

  React.useEffect(() => {
    const rC = new RouletteController()
    spin_roulette(rC)

  }, [activeCampaignID])


  if (!selectedId) { return <EmptyView></EmptyView> }

  return <Roulette items={probs} rouletteParams={rouletteParams} selectedOption={selectedId} onModalSubmit={onModalSubmit} />


}


export const Roulette = ({ items = [], primaryLabel = "GIRÁ Y GANÁ", secondaryLabel = "CON LA BILLETERA WALLY", rouletteParams = {}, selectedOption = "", onModalSubmit }) => {




  return (
    <div className="content">

      <h1 className="RouletteText" style={{ color: rouletteParams?.wheel_text_color_1 }} >{primaryLabel}</h1>
      <h1 className="RouletteText2" style={{ color: rouletteParams?.wheel_text_color_2 }} >{secondaryLabel}<strong></strong></h1>

      {/* <Wheel items={items.map(item=>item.label)} /> */}
      <Wheel
        items={items}
        selectedOption={selectedOption}
        rouletteParams={rouletteParams}
        onModalSubmit={onModalSubmit} />

    </div>
  );
}

