import React, { useState, useEffect } from "react";

export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
      audio.currentTime=0;
      playing ? audio.play() : audio.pause();

    },
    [playing]
  );

  useEffect(() => {
    audio.setAttribute("loop",true)
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};