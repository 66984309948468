
import firebase from 'firebase'

export var firebaseConfig = {
  apiKey: "AIzaSyAZkMmf3Zlu_NkcqEyWuAp_J05PQ9XGesU",
  authDomain: "warden-roulette.firebaseapp.com",
  projectId: "warden-roulette",
  storageBucket: "warden-roulette.appspot.com",
  messagingSenderId: "851282842322",
  appId: "1:851282842322:web:a1625f3868f5d87b3e130b"
  };
  
export const firebaseApp=firebase.initializeApp(firebaseConfig);
const db=firebase.firestore();
export const auth=firebase.auth()
export const firebaseObj=firebase

export default db;