import { uuid } from "uuidv4";
export const setRouletteStyles=(data)=>{

    document.documentElement.style.background=`url(${data.background_file}) no-repeat center`
    document.documentElement.style.backgroundSize="cover"

    document.documentElement.style.setProperty(
        "--wheel-size",
        data.roulette_size_1 + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-slice-spacing",
        data.roulette_div_size + "px"
      );
      document.documentElement.style.setProperty("--nb-turn", data.spins_turns);
      document.documentElement.style.setProperty(
        "--spinning-duration",
        data.spins_duration + "s"
      );
      document.documentElement.style.setProperty(
        "--wheel-border-size",
        data.roulette_border_size + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-color",
        data.wheel_color_1
      );
      document.documentElement.style.setProperty(
        "--neutral-color",
        data.wheel_color_2
      );
      document.documentElement.style.setProperty(
        "--logo_background_size",
        data.logo_size + "px"
      );
      document.documentElement.style.setProperty(
        "--wheel-font-size",
        data.font_size + "px"
      );
}





export const handleProbabilitys = (event, probability, payload = {}) => {
  let probabilityi = [...probability];
  const { id, data } = payload;
  const randid=uuid()
  switch (event) {
    case "add": {
      probabilityi.push({
        id: randid,
        label: "",
        prize: "",
        probability: 1,
      });
      break;
    }
    case "delete": {
      probabilityi = probabilityi.filter((item) => item.id !== id);
      break;
    }
    case "edit": {
      const selected = probabilityi.find((item) => item.id === id);
      selected.label = data.label;
      selected.prize = data.prize;
      selected.probability = data.probability;
      break;
    }
  }
  return probabilityi;
};


const defaultRoulleteValues = {
  roulette_size_1: 290,
  roulette_size_2: 290,
  roulette_div_size: 60,
  logo_size: 60,
  spins_turns: 5,
  spins_duration: 4,
  font_size: 18,
  roulette_border_size: 10,
  wheel_color_1: "#1fc4d0",
  wheel_color_2: "#FFFFFF",
  play_again: false,
  play_again_count: 1,
  background_file: "/",
  wheel_text_color_1:"#fff0",
  wheel_text_color_2:"#fff0",

};

export const loadRouletteParams=(newData)=>{
  const oldData_={}
  Object.keys(defaultRoulleteValues)
        .map(k=>{
          oldData_[k]=newData?.[k]||defaultRoulleteValues[k]
        })
  return oldData_

}