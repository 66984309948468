import React from "react";
import swal from "@sweetalert/with-react";
import "./index.css";
import Confetti from "react-confetti";
import {
  ci_Numeric_Error_Message,
  data_Validation,
  isValidCI,
  isValidPhone,
  phone_Numeric_Error_Message,
} from "./validators";
import { useAudio } from "../audio/Audio";

const defaultParams = {
  phone_Number: "",
  ci_Number: "",
};

const rouletteSoundURL =
  "https://firebasestorage.googleapis.com/v0/b/warden-roulette.appspot.com/o/rouletteL.mp3?alt=media&token=2046cb4d-2934-464d-9643-f4a2f51348ac";

export const Wheel_ = ({
  items = [],
  onModalSubmit = () => {},
  selectedOption,
  rouletteParams = {},
  soundURL = rouletteSoundURL,
}) => {
  const [spinning, setSpinning] = React.useState(false);
  const [confetti, setconfetti] = React.useState(false);
  const [close_modal, setClose_modal] = React.useState(false);
  const [playing, toggle] = useAudio(soundURL);

  React.useEffect(() => {
    if (playing != spinning) {
      toggle();
    }
  }, [spinning]);

  const submitData = React.useRef({ ...defaultParams });
  const setSubmitData = (changeObj = {}) => {
    const newData = { ...submitData.current, ...changeObj };
    submitData.current = newData;
  };

  const selectedItem = items.find((item) => item.id == selectedOption)?.index;
  const wheelVars = {
    "--nb-item": items.length,
    "--selected-item": selectedItem,
  };

  const spinRoulette = (interval) => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
      setconfetti(true);
      openModal();
    }, interval);
  };

  const openModal = () => {
    swal(
      <div>
        
        <br></br>
        <div className="textmodal">
        <h2>
          Descargá la aplicación y completa tus datos en las próximas 24 horas
          para recibir tu premio
        </h2>
          {/* <h2>Completa tus datos para recibir!!!</h2> */}
        </div>
        {/* <a className="textmodal" href="https://www.wally.com.py">Bases y Condiciones</a> */}
        <br></br>
        <br></br>
        <div>
          <label className="textmodal">
            Número de Teléfono:
            <input
              type="text"
              className="inputModal"
              name="Telefono"
              onChange={(value) => {
                phone_Numeric_Error_Message(value.target.value);
                setSubmitData({ phone_Number: value.target.value });
              }}
            />
          </label>
        </div>
        <div>
          <span id="error_phone_Numeric_Modal" className="error_Text_Modal">
            *Número de Teléfono Invalido!
          </span>
          <span id="error_phone_Numeric_Exit" className="error_Text_Modal">
            *Campo Requerido!
          </span>
        </div>
        <br></br>
        <br></br>
        <div>
          <label className="textmodal">
            Número de Cédula:
            <input
              type="text"
              className="inputModal"
              name="Cedula"
              onChange={(value) => {
                ci_Numeric_Error_Message(value.target.value);
                setSubmitData({ ci_Number: value.target.value });
              }}
            />
          </label>
        </div>
        <div>
          <span id="error_ci_Numeric_Modal" className="error_Text_Modal">
            *Número de Cédula Invalido!
          </span>
          <span id="error_ci_Numeric_Exit" className="error_Text_Modal">
            *Campo Requerido!
          </span>
        </div>
        <br></br>
        <button
          className="swal-button"
          onClick={() => {
            if (
              !(
                isValidCI(submitData.current.ci_Number) &&
                isValidPhone(submitData.current.phone_Number)
              )
            ) {
              return;
            }
            setClose_modal(true);
            setconfetti(false);
            onModalSubmit && onModalSubmit({ ...submitData.current });
            submitData.current = {};
          }}
        >
          Aceptar
        </button>
      </div>,

      {
        title: "¡Felicidades!",
        text: "\n\nGanaste " + items[selectedItem]?.label,
        style: "headermodal",
        closeOnClickOutside: false,
        closeOnEsc: false,
        close: close_modal,
        button: false,
      }
    );
  };

  return (
    <div className="wheel-background">
      <div className="wheel-container">
        <div
          className={`wheel ${spinning ? "spinning" : ""}`}
          style={wheelVars}
        >
          {items.map((item, index) => (
            <div
              className="wheel-item"
              key={index}
              style={{ "--item-nb": index }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <br />
        <button
          id="btnAnimar"
          disabled={spinning}
          onClick={() =>
            spinRoulette((rouletteParams.spins_duration || 1) * 1000)
          }
          className="btn btn-warning btn-orange btn-lg"
        >
          GIRA LA RULETA
        </button>
      </div>
      <div>
        {confetti ? (
          <Confetti
            run={true}
            numberOfPieces={1200}
            tweenDuration={100}
            gravity={0.25}
            className="confetti_drop"
          />
        ) : null}
      </div>
    </div>
  );
};

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      phone_Number: "",
      ci_Number: "",
      close_modal: false,
      confetti_bool: false,
    };
    this.selectItem = this.selectItem.bind(this);
  }

  selectItem() {
    if (this.state.selectedItem === null) {
      let selectedItem = Math.floor(Math.random() * 100);

      if (this.props.onSelectItem) {
        this.props.onSelectItem(selectedItem);
      }
      this.setState({ selectedItem });
      console.log("Score", selectedItem);
      switch (true) {
        case selectedItem <= 25:
          console.log("Premio 1");
          this.setState({ selectedItem: 0 });
          selectedItem = 0;
          break;
        case selectedItem > 25 && selectedItem <= 35:
          console.log("Premio 2");
          this.setState({ selectedItem: 1 });
          selectedItem = 1;
          break;
        case selectedItem > 35 && selectedItem <= 50:
          console.log("Premio 3");
          this.setState({ selectedItem: 2 });
          selectedItem = 2;
          break;
        case selectedItem > 50 && selectedItem <= 75:
          console.log("Premio 4");
          this.setState({ selectedItem: 3 });
          selectedItem = 3;
          break;
        case selectedItem > 75 && selectedItem <= 100:
          console.log("Premio 5");
          this.setState({ selectedItem: 4 });
          selectedItem = 4;

          break;
        default:
          break;
      }

      const { items } = this.props;
      if (selectedItem !== null) {
        setTimeout(() => {
          this.setState({ confetti_bool: true });
          swal(
            <div>
              {/* <Confetti
                  run={this.state.confetti_bool}
                  // width={1000}
                  // height={1000}
                  initialVelocityY= {18}
                  // initialVelocityX={15}
                  // confettiSource={10,0,0}
                  numberOfPieces={1700}
                   gravity={0.04}
                  className="confetti_drop"
                  /> */}
              <br></br>
              <div className="textmodal">
                <h2>Completa tus datos para recibir!!!</h2>
              </div>
              <a className="textmodal" href="https://www.wally.com.py">
                Bases y Condiciones
              </a>
              <br></br>
              <br></br>
              <div>
                <label className="textmodal">
                  Número de Teléfono:
                  <input
                    type="text"
                    className="inputModal"
                    name="Telefono"
                    onChange={(value) => {
                      phone_Numeric_Error_Message(value.target.value);
                      this.setState({ phone_Number: value.target.value });
                    }}
                  />
                </label>
              </div>
              <div>
                <span
                  id="error_phone_Numeric_Modal"
                  className="error_Text_Modal"
                >
                  *Número de Teléfono Invalido!
                </span>
                <span
                  id="error_phone_Numeric_Exit"
                  className="error_Text_Modal"
                >
                  *Campo Requerido!
                </span>
              </div>
              <br></br>
              <br></br>
              <div>
                <label className="textmodal">
                  Número de Cédula:
                  <input
                    type="text"
                    className="inputModal"
                    name="Cedula"
                    onChange={(value) => {
                      ci_Numeric_Error_Message(value.target.value);
                      this.setState({ ci_Number: value.target.value });
                    }}
                  />
                </label>
              </div>
              <div>
                <span id="error_ci_Numeric_Modal" className="error_Text_Modal">
                  *Número de Cédula Invalido!
                </span>
                <span id="error_ci_Numeric_Exit" className="error_Text_Modal">
                  *Campo Requerido!
                </span>
              </div>
              {/* <div className="btnRetirar">
              <button className=".swal-button" onClick={()=>{cerrarModal=true;console.log("Valor",cerrarModal)}} >Retirar!</button>
            </div> */}
              <br></br>
              <button
                className="swal-button"
                onClick={() =>
                  data_Validation(this.state.phone_Number, this.state.ci_Number)
                }
              >
                Aceptar
              </button>
            </div>,

            {
              title: "Felicidades!!!",
              text:
                "Ganaste " +
                items[selectedItem] +
                "\n\n Descargá la aplicación y completa tus datos en las próximas 24 horas para recibir tu premio",
              style: "headermodal",
              closeOnClickOutside: false,
              closeOnEsc: false,
              close: this.state.close_modal,
              button: false,
            }

            // InputNumeroModal:{
            //   element:"input",
            //   attributes: {
            //     placeholder: "Ej.:0981000000",
            //     type: "password",
            //     className: "btnRetirar",
            //   },
            // },
            // },
          );
        }, 4000);
      }
    } else {
      this.setState({ selectedItem: null });
      setTimeout(this.selectItem, 500);
    }
  }

  render() {
    const { selectedItem } = this.state;
    const { items } = this.props;
    const wheelVars = {
      "--nb-item": items.length,
      "--selected-item": selectedItem,
    };
    const spinning = selectedItem !== null ? "spinning" : "";

    return (
      <div className="wheel-background">
        <div className="wheel-container">
          <div className={`wheel ${spinning}`} style={wheelVars}>
            {items.map((item, index) => (
              <div
                className="wheel-item"
                key={index}
                style={{ "--item-nb": index }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <br />
          <button
            id="btnAnimar"
            disabled={
              spinning === "spinning"
                ? (document
                    .getElementsByTagName("button")[0]
                    .setAttribute("class", "btnAfterClick"),
                  true)
                : false
            }
            onClick={this.selectItem}
            className="btn btn-warning btn-orange btn-lg"
          >
            GIRA LA RULETA
          </button>
        </div>
        <div>
          <Confetti
            run={this.state.confetti_bool}
            // width={1000}
            // height={1000}
            // initialVelocityY= {18}
            // initialVelocityX={15}
            // confettiSource={10,0,0}
            numberOfPieces={1200}
            gravity={0.25}
            className="confetti_drop"
          />
        </div>
      </div>
    );
  }
}
