import db, { auth, firebaseObj } from './firebase';


export const enableFirestorePersistence = db => {
  db.enablePersistence()
    .then(() => {
      console.log('Firestore persistence ready');
    })
    .catch(err => {
      console.log('Firestore persistence error', err.code);
    });
};

export const enableAuthPersistence=(callback=()=>{})=>{
  const auth=firebaseObj.auth
  auth().setPersistence(auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log('Firebase Auth persistence ready');
    callback({error:false})
  })
  .catch(err => {
    console.log('Firebase Athh persistence error', err.code);
    callback({error:true,code:err?.code})
  });
}



export class RouletteController{
  urlBase="https://us-central1-warden-roulette.cloudfunctions.net/wally"
  constructor(){

  }

  getBaseRequest=(callback=()=>{},extraBody={})=>{
    auth.currentUser.getIdToken()
    .then(token=>{
      const raw = JSON.stringify({"tokenId":token,...extraBody});
      const headers=new Headers()
      headers.append("Content-Type", "application/json");
      const request = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      }
        callback({error:false,request:request})
    })
    .catch(reason=>{callback({error:true,request:reason})})
  }


  register=(userData={},callback=()=>{})=>{
    this.getBaseRequest(({error,request})=>{
      if(error){callback({error:true,data:reason});return}
      fetch(`${this.urlBase}/register`,request)
      .then(response=>response.json())
      .then(data=>callback({error:false,data:data}))
      .catch(reason=>callback({error:true,data:reason}))
    },userData)

  }



  spin_roulette=(callback=()=>{})=>{
    this.getBaseRequest(({error,request})=>{
      if(error){callback({error:true,data:reason});return}
      fetch(`${this.urlBase}/spin_roulette`,request)
      .then(response=>response.json())
      .then(data=>callback({error:false,data:data}))
      .catch(reason=>callback({error:true,data:reason}))
    })
}



}

export default class dbController {
  constructor(firebase, enablePer = false) {
    this.db = db;
    if (enablePer) {
      enableFirestorePersistence(this.db);
    }
  }

  



  createObject = (collection, data, callback) => {
    this.db
      .collection(collection)
      .add(data)
      .then(data_ => {
        callback({error:false,data:data_});
      })
      .catch((reason)=> {
        callback({error:true,data:reason})
      });
  };


  
  getObjectsIdSnap = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      // .onSnapshot(snapshot => {
      //   snapshot.docChanges().forEach(change => {
      //     const doc = change.doc;
      //     callback({
      //       id: doc.id,
      //       data: doc.data(),
      //       type: change.type,
      //       size: snapshot.size,
      //     });
      //   });
      // });
      .onSnapshot((doc) => {
        callback({
          id: doc.id,
          data: doc.data(),
          // type: change.type,
          // size: snapshot.size,
        });
    });
  };

  
  getObjectsId = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      // .onSnapshot(snapshot => {
      //   snapshot.docChanges().forEach(change => {
      //     const doc = change.doc;
      //     callback({
      //       id: doc.id,
      //       data: doc.data(),
      //       type: change.type,
      //       size: snapshot.size,
      //     });
      //   });
      // });
      .get().then((doc) => 
      callback({
        id: doc.id,
        data: doc.data(),
        // type: change.type,
        // size: snapshot.size,
      })
      
      
      )
  };

  getObjectsSnap = (collection, callback) => {
    const unsuscribe = this.db.collection(collection).onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        const doc = change.doc;
        callback({ id: doc.id, data: doc.data(), type: change.type });
      });
    });
    return unsuscribe;
  };

  editFirstLevel = (id, collection, data, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .update(data)
      .then(() => callback({ error: false, data }))
      .catch((error)=> {
        callback({ error: true, data:error });
      });
  };

  deleteDoc = (collection, id, callback) => {
    this.db
      .collection(collection)
      .doc(id)
      .delete()
      .then(result => {
        callback({ error: false, result });
      })
      .catch(reason => {
        callback({ error: true, result: reason });
      });
  };

}