import { Skeleton } from "@mui/material";
import React from "react";

export const EmptyView = ({}) => {
  return (
    <div className="content" >
      <div style={{marginInline: "25%" }}>
      <br/><br/>
        <Skeleton
          variant="rectangle"
          animation="wave"      
          className="RouletteText"
          width= "50vw"
          height= "5vh"
          style={{
            paddingTop: "5vh",
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        />
        <br></br>
      </div>
      <div style={{marginInline: "25%" }}>
        <Skeleton
          variant="rectangle"
          animation="wave" 
          className="RouletteText2"
          width= "50vw"
          height= "5vh"
          style={{   
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        />
        <br></br>
      </div>
      <div style={{ marginTop: "3%", padding: "3px" }}>
        <Skeleton
          variant="circular"
          animation="wave"
          className="wheel-background"
          style={{
            width: "calc(var(--wheel-size) + 2 * var(--wheel-border-size))",
            height: "calc(var(--wheel-size) + 2 * var(--wheel-border-size))",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        />
      </div>
      <div style={{marginInline: "25%" }}>
          <br></br>
      <Skeleton
          variant="rectangle"
          animation="wave"
          width= "50vw"
          height= "7vh"
          style={{     
            textAlign: "center",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        />
      </div>
    </div>
  );
};
