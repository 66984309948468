import { CircularProgress } from 'material-ui'


import React from 'react'

export const Loading=({show})=>{



    if(!show){
        return null
    }

    return (
        
        <div style={{width:"100vw",height:"100vh",display:"flex",backgroundColor:"#0000ff22"}}>
            {/* <CircularProgress  color="inherit" /> */}
        </div>
        
    )
}