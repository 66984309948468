import ReactDOM from "react-dom";
import { RouletteWrapper } from "./pages/roulette";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles.css";
import withFirebaseAuth from "react-with-firebase-auth";
import { firebaseApp } from "./service/firebase";
import { StoreProvider } from "./context/store";
import { enableAuthPersistence } from "./service/dbController";
import { Loading } from "./components/Loading/Loading";
import { EmptyView } from './components/Loading/Empty';

const firebaseAppAuth = firebaseApp.auth();


const App=({user,signOut,signInAnonymously})=> {
    React.useEffect(()=>{
      enableAuthPersistence(({error})=>{
        if(error){return}
        signInAnonymously().then(response=>{
        })
      })
      
    },[])
    
    if(!user){
      // return <Loading show={!user}></Loading>}
      return <EmptyView/>}
    return (
      <Router>
        <div>
          {/* <button onClick={() => {this.state.signInAnonymously()}}>SignIn</button> */}
          <Switch>
            <StoreProvider user={user}>
              <Route exact path="/" component={RouletteWrapper} />
            </StoreProvider>
          </Switch>
        </div>
      </Router>
    );
}



const App_= withFirebaseAuth({
  firebaseAppAuth,
})(App);

const rootElement = document.getElementById("root");
ReactDOM.render(<App_ />, rootElement);



