export const data_Validation = (data1, data2) => {
    data1 !== "" && !isNaN(data1) && data2 !== "" && !isNaN(data2)
      ? swal({ icon: "success", buttons: false, timer: 2000 })
      : data1 === "" && data2 === ""
      ? (document.getElementById("error_ci_Numeric_Exit").style.display =
          "inline") &&
        (document.getElementById("error_phone_Numeric_Exit").style.display =
          "inline")
      : data1 === "" && data2 !== ""
      ? (document.getElementById("error_phone_Numeric_Exit").style.display =
          "inline")
      : data2 === ""
      ? (document.getElementById("error_ci_Numeric_Exit").style.display =
          "inline")
      : null;

  };

export const validateResponse=(error,timer=2000)=>{
  const valid=error?{
    icon:"error",
    text: "Promo caducada para este usuario",
    style: "headermodal",

  }:{
    icon:"success",
    text: "Premio registrado!!",
    style: "headermodal",

  }
  swal({  buttons: false, timer:timer ,...valid})
}

export const isValidNumber=(value,min=1, max=10)=>{
  if(!value){return false}
  if(isNaN(value)){return false}
  const value_=`${value}`
  if(value_<0){return false}
  if(value_.length<min){return false}
  if(value_.length>max){return false}
  return true


}

export const isValidPhone=(value)=>{
  return isValidNumber(value,1,10)
}

export const isValidCI=(value)=>{
  return isValidNumber(value,3,8)
}

export const phone_Numeric_Error_Message = (value) => {
    document.getElementById("error_phone_Numeric_Exit").style.display = "none";
    isValidPhone(value)
        ? (document.getElementById("error_phone_Numeric_Modal").style.display =
            "none")
        : (document.getElementById("error_phone_Numeric_Modal").style.display =
            "inline")
  };

export const ci_Numeric_Error_Message = (value) => {
    document.getElementById("error_ci_Numeric_Exit").style.display = "none";
    isValidCI(value)
        ? (document.getElementById("error_ci_Numeric_Modal").style.display =
            "none")
        : (document.getElementById("error_ci_Numeric_Modal").style.display =
            "inline")
  };