import React, { useEffect, useState } from "react";
import dbController from '../service/dbController';
import firebase from 'firebase'

const dbCon= new dbController();



const initialStore={
  data:{
    campaign:{},
    settings:{general:{}}
  },
  dispatch:()=>{}
}



const storeContext=React.createContext(initialStore)

export const StoreProvider = (props)=>{
  const listeners=React.useRef([])
  const dispatchStore = (collection, response) => {   
    const { id, type } = response;
    const dataI = response.data;
    // console.log('[dispatch]', { collection, dataI, id, type });
    const collectionData = data[collection] || {};
    switch (type) {
      case 'added':  
        collectionData[id] = dataI;
        setData({ ...data, [collection]: collectionData });
        break;

      case 'modified':
        collectionData[id] = dataI;
        setData({ ...data, [collection]: collectionData });
        break;

      case 'removed':
        delete collectionData[id];
        setData({ ...data, [collection]: collectionData });
        break;

      default:
        break;
    }
  };

  const [data,setData]=React.useState(initialStore.data)

  useEffect(()=>{
    if(props.user){
      const ents=['campaign','settings']
      ents.forEach(item=>{
        listeners.current.push(dbCon.getObjectsSnap(item,(resp)=>{dispatchStore(item,resp)}))
      })
    }
       return(
      ()=>listeners.current.map((item)=>(item()))
      )
  },[props.user])
  // console.log("algo",data)
  return(
      <storeContext.Provider value={{data}}>
        {props.children}
      </storeContext.Provider>
    )

}
export const useStore=()=>React.useContext(storeContext)
